import { Injectable } from '@angular/core';
import {
    createCanvasTransform,
    createMediapipeSegmenter,
    createVideoProcessor,
    createVideoTrackProcessor,
    createVideoTrackProcessorWithFallback,
    PROCESSING_HEIGHT,
    PROCESSING_WIDTH,
    SegmentationTransform,
    VideoProcessor
} from '@pexip/media-processor';
import { LoggingService } from 'src/core/logging.service';
import { StorageService } from 'src/core/services';
import { BackgroundEffects } from 'src/core/services/storage/settings.model';
import { HAS_WEBGL } from '../../platform.service';

@Injectable({
    providedIn: 'root'
})
export class VideoProcessing {
    public static readonly DEFAULT_REPLACEMENT =
        'assets/default-replacement.jpg';

    hasError = false;

    private videoProcessor?: VideoProcessor;
    private segmentationTransform?: SegmentationTransform;

    constructor(
        private storageService: StorageService,
        private loggingService: LoggingService
    ) {
        if (!HAS_WEBGL) {
            return;
        }

        const segmenter = createMediapipeSegmenter(
            new URL(
                './assets/selfie_segmentation/',
                document.baseURI
            ).toString(),
            {
                modelType: 'general',
                processingWidth: PROCESSING_WIDTH,
                processingHeight: PROCESSING_HEIGHT,
                gluePath: new URL(
                    './assets/selfie_segmentation/selfie_segmentation.js',
                    document.baseURI
                ).toString()
            }
        );
        this.segmentationTransform = createCanvasTransform(segmenter, {
            effects: this.activeEffect,
            width: PROCESSING_WIDTH,
            height: PROCESSING_HEIGHT,
            backgroundBlurAmount: 7,
            bgImageUrl:
                this.storageService.userSettingsProxy.desktopBackgroundFile ||
                VideoProcessing.DEFAULT_REPLACEMENT
        });

        this.storageService.userSettingsStorage.listeners.enableBackgroundEffects$.subscribe(
            () => {
                this.segmentationTransform.effects = this.activeEffect;
            }
        );

        this.storageService.userSettingsStorage.listeners.activeEffect$.subscribe(
            () => {
                this.segmentationTransform.effects = this.activeEffect;
            }
        );
    }

    private get activeEffect() {
        return !this.storageService.userSettingsProxy.activeEffect ||
            !this.storageService.userSettingsProxy.enableBackgroundEffects
            ? 'none'
            : this.storageService.userSettingsProxy.activeEffect ===
              BackgroundEffects.replacement
            ? 'overlay'
            : 'blur';
    }

    async connectBlur(mediaStream: MediaStream) {
        try {
            this.videoProcessor = createVideoProcessor(
                [this.segmentationTransform],
                'MediaStreamTrackProcessor' in window &&
                    this.shouldUseVideoTrackProcessor()
                    ? createVideoTrackProcessor()
                    : createVideoTrackProcessorWithFallback()
            );
            await this.videoProcessor.open();
            return this.videoProcessor.process(mediaStream);
        } catch (reason) {
            this.loggingService.info('connectBlur failed', reason);
            return mediaStream;
        }
    }

    handleFileChanged(url = VideoProcessing.DEFAULT_REPLACEMENT) {
        this.segmentationTransform?.loadBackgroundImage(url);
    }

    cleanup() {}

    private shouldUseVideoTrackProcessor() {
        /**
         * NavigatorUABrandVersion interface
         * @see {@link https://wicg.github.io/ua-client-hints/#dictdef-navigatoruabrandversion}
         */
        interface NavigatorUABrandVersion {
            brand: string;
            version: string;
        }
        /**
         * NavigatorUAData interface
         * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/NavigatorUAData}
         */
        interface NavigatorUAData {
            brands: NavigatorUABrandVersion[];
            mobile: boolean;
            platform: string;
        }

        interface ExtendedNavigator extends Navigator {
            userAgentData: NavigatorUAData;
        }

        // https://developer.mozilla.org/en-US/docs/Web/API/NavigatorUAData
        // userAgentData is currently only available in Chromium as well as the
        // MediaStreamTrackProcessor API
        if ('userAgentData' in navigator) {
            const {
                brands,
                platform
            } = (navigator as ExtendedNavigator).userAgentData;
            // Avoid using the API to workaround the browser bug introduced by the
            // older version of M111 for Windows
            if (
                platform === 'Windows' &&
                brands.some(
                    brand =>
                        brand.brand === 'Chromium' &&
                        parseInt(brand.version, 10) < 111
                )
            ) {
                return false;
            }
            return true;
        }
        // For other browsers, MediaStreamTrackProcessor API is not available
        return false;
    }
}
